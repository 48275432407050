<template>
  <v-container fluid class="mt-5">
    <v-row justify="center">
      <v-col cols="11">
        <v-expand-transition>
          <Loader v-if="loading" />
          <v-row v-else>
            <CreateChampionship @created="getChampionships" />

            <v-col
              lg="8"
              md="8"
              sm="12"
              cols="12"
            >
              <v-text-field
                v-model="search"
                filled
                append-icon="mdi-magnify"
                background-color="white"
                label="Pesquisar"
                hint="Digite o nome do campeonato e aperte enter"
                persistent-hint
                @click:append="getChampionships"
                v-on:keyup.enter="getChampionships"
              />
            </v-col>

            <v-col
              lg="4"
              md="4"
              sm="12"
              cols="12"
              class="d-flex flex-row"
            >
              <v-select
                v-model="championships.pageSize"
                :items="pageSizeItens"
                filled
                background-color="white"
                label="Itens a serem carregados"
              />

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    height="56px"
                    class="ml-5"
                    v-bind="attrs"
                    v-on="on"
                    @click="createChampionship()"
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </template>
                <span>Cadastrar campeonato</span>
              </v-tooltip>
            </v-col>

            <v-col
              lg="4"
              md="6"
              sm="12"
              cols="12"
              v-for="championship in championships.data"
              :key="championship._id"
            >
              <ChampionshipCard :data="championship" />
            </v-col>

            <v-col
              v-if="championships.data.length > 0"
              cols="12"
            >
              <div class="text-center">
                <v-pagination
                  v-model="championships.currentPage"
                  :length="championships.totalPages"
                ></v-pagination>
              </div>
            </v-col>

            <v-col
              v-else
              cols="12"
            >
              <EmptyData />
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {
    Loader: () => import('@/components/LoadingCards'),
    EmptyData: () => import('@/components/EmptyData'),
    ChampionshipCard: () => import('./components/ChampionshipCard.vue'),
    CreateChampionship: () => import('./components/CreateChampionship.vue')
  },
  data () {
    return {
      loading: false,
      search: '',
      selectedChampionship: '',
      pageSizeItens: [
        5,
        10,
        25
      ]
    }
  },
  watch: {
    'championships.currentPage' (val) {
      this.getChampionships()
    },
    'championships.pageSize' (val) {
      this.getChampionships()
    }
  },
  computed: {
    championships: {
      get () {
        return this.$store.state.championships
      },
      set (val) {
        this.$store.commit('SET_CHAMPIONSHIPS', val)
      }
    },
    createChampionshipModal: {
      get () {
        return this.$store.state.createChampionshipModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'createChampionshipModal',
          value: val
        })
      }
    }
  },
  mounted () {
    this.getChampionships()
  },
  methods: {
    getChampionships () {
      const { currentPage: page, pageSize } = this.championships
      this.loading = true

      var url = `/championships?page=${page}&pageSize=${pageSize}`
      if (this.search) {
        url = `${url}&searchName=${this.search}`
      }

      this.$http.get(url)
        .then((res) => {
          this.loading = false
          this.championships = res.data
          if (page > res.data.totalPages) {
            this.championships = {
              ...this.championships,
              currentPage: 1
            }
          }
        })
        .catch((err) => {
          this.$toast.error(err.response.data.message)
        })
    },
    createChampionship () {
      this.createChampionshipModal = true
    }
  }
}
</script>
